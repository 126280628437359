<template>
  <div>
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
   
  }
}
</script>

<style lang='less'>
body{
  padding: 0;
  margin: 0;
  text-align: justify;
  // overflow-x: hidden;
}
</style>

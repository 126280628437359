<template>
    <div class="row">
        <img src="@/assets/images/left_ico.png" alt="">
        <span>{{title}}</span>
        <img src="@/assets/images/right_ico.png" alt="">
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type:String,
            default:'标题'
        }
    },
    data(){
        return{

        }
    }
}
</script>
<style scoped lang='less'>
.row{
    width: 100%;
    line-height: 48px;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN, SourceHanSansCN;
    img{
        width: 30px;
        height: 30px;
    }
    span{
        font-weight: bold;
font-size: 38px;
color: #232529;
line-height: 48px;
font-style: normal;
margin: 0 32px;
    }
}
</style>